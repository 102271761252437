import React from 'react';
import { Helmet } from 'react-helmet';

function SEO({
  title,
  description,
  keywords,
  url = 'https://www.innerloop.stream/',
  imgUrl = 'https://play.innerloop.stream/playersvc/ins-share/cu-MpW58aYzuiAP0C30qmTx_?urlmode=true&ai=none',
}) {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {description && <meta name="twitter:card" content={description} />}
      {description && <meta name="twitter:description" content={description} />}
      <meta property="og:url" content={url} />
      <meta property="og:image" content={imgUrl} />
    </Helmet>
  );
}

export default SEO;
