/* eslint-disable no-case-declarations */
import { getDaysUntillExpiry } from 'helpers/Utils';
import { STORE_SUBSCRIPTION, UPDATE_SUBSCRIPTION } from '../actions';
import {
  defaultAiProperties,
  getAiProperties
} from 'helpers/subscriptionHelper';

const INIT_STATE = {
  workFlowEnabled: false,
  optimizationEnabled: false,
  allowDownload: false,
  imageAIAnalytcis: false,
  dashboardAnalytics: false,
  spaceSummaryAnalytics: false,
  accessManagement: false,
  adaptiveStreaming: false,
  drmEnabled: false,
  aesEnabled: false,
  subscriptionExpired: false,
  nonStreamingEnabled: false,
  recordingEnabled: false,
  isLoading: true,
  autoTranscribe: false,
  allowPlayerWhitelabel: false,
  playerAnalytics: false,
  allData: {},
  daysUntillExpiry: 10000000,
  aiProperties: defaultAiProperties
};

// We usally check this values by using subscriptionChecker function from src / helpers / subscriptionChecker.js file.We often use subscriptionDetails prop(which we pass in src / views / app / index.js) which is sometimes undefined.Instead of using above method we will use this approach;

// import { useSelector } from 'react-redux';
// const selector = useSelector(state => state.subscription);

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case STORE_SUBSCRIPTION:
      const daysUntilExpiry = getDaysUntillExpiry(
        action?.payload?.endDateTime,
        true
      );
      const aiValues = action.payload.ai;
      return {
        workFlowEnabled: action?.payload?.workflowEnabled,
        allowDownload: action?.payload?.allowDownload,
        imageAIAnalytcis: action?.payload?.imageAIAnalytcis,
        dashboardAnalytics: action?.payload?.dashboardAnalytics,
        spaceSummaryAnalytics: action?.payload?.spaceSummaryAnalytics,
        accessManagement: action?.payload?.accessManagement,
        adaptiveStreaming: action?.payload?.adaptiveStreaming?.length > 0,
        drmEnabled: action?.payload?.drmEnabled,
        aesEnabled: action?.payload?.aesEnabled,
        optimizationEnabled: action?.payload?.optimization,
        nonStreamingEnabled: action?.payload?.nonStreamingEnabled,
        recordingEnabled: action?.payload?.recordingEnabled,
        allowPlayerWhitelabel: action?.payload?.allowPlayerWhitelabel,
        subscriptionExpired:
          new Date() > new Date(action?.payload?.endDateTime) ||
          !action?.payload?.id,
        isLoading: false,
        allData: action?.payload,
        autoTranscribe: action?.payload?.autoTranscribe,
        playerAnalytics: action?.payload?.playerAnalytics,
        daysUntillExpiry: daysUntilExpiry,
        aiProperties: getAiProperties(aiValues)
      };
    case UPDATE_SUBSCRIPTION:
      return {
        ...state,
        subscriptionExpired: action.payload
      };
    default:
      return state;
  }
};
